import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button } from '@zydalabs/zac-react';

import { Modal } from 'components/kit';
import { context as localeContext } from 'context/locale';
import { context as userContext } from 'context/user';
import { ORDER_STATUS } from 'constants/order';
import { DELIVERY_BUSY_MODE } from 'components/common/branch/Busy/utils';
import { Text } from 'components/service';
import * as translations from 'constants/translations';
import { useAssignScheduledOrderNow, useFetchVerdDrivers } from 'service/hooks';
import { useSelectedStore } from 'hooks';
import { ORDER_RIDER_STATUS } from 'constants/orderRiderStatus';
import OrderDeliveryConfirmation from 'components/common/orders/OrderDeliveryConfirmation';
import AddRiderModal from './AddRiderModal';
import useHandleAssignUnAssignDriverToOrder from './handleAssignDrivertoOrder';
import AssignDeliveryModal from './AssignDeliveryModal';
import useHandleAssignUnAssignCourierToOrder from './handleAssignCourierToOrder';
import useGetCourierInfoWithLogo from './useGetCourierInfoWithLogo';

export const DeliveryActionButtons = ({
  order,
  isUpdatingStatus,
  shouldDisableActionBtns,
  sendChangeStatus,
  deliveryType,
  open,
  close,
  closeable,
  updatedCourierId,
}) => {
  const { status: orderStatus, branchData } = order || {};
  const { lang, translate } = useContext(localeContext);
  const {
    selectedStore: { restaurantCourierSetting },
  } = useContext(userContext);
  const { user } = useContext(userContext);
  const assignScheduledOrderNow = useAssignScheduledOrderNow();
  const selectedStoreId = useSelectedStore();
  const { data: activeRiders, mutate: mutateFetchVerdDrivers } = useFetchVerdDrivers({
    storeId: selectedStoreId,
    branchId: parseInt(branchData.id),
  });
  const verd = restaurantCourierSetting?.find(courier => courier.isInternalDelivery);

  const [shouldShowRiderModal, setShouldShowRiderModal] = useState(verd?.showRiderModal || false);

  const riders = activeRiders?.length ? activeRiders.filter(rider => rider.onShift) : [];
  const deliveryOrderStatus = order?.deliveryStatus;
  const isOrderDeliveryCourierNotVerd = !order?.deliveryCourier?.isInternalDelivery;
  const isScheduledOrder = order?.isScheduled;
  const isInternalDelivery = order?.deliveryCourier?.isInternalDelivery;
  const isPendingScheduledOrder = isScheduledOrder && deliveryOrderStatus === ORDER_RIDER_STATUS.PENDING;
  const orderDeliveryCourierId = order?.deliveryCourier?.courierId;

  const openOrderDeliveryConfirmationPopUp = ({
    assignDriverToOrder,
    assignee,
    isAssigneeSupportCancellation,
    isUnAssignCourier,
    acceptOrder,
  }) =>
    open({
      title: <Text value={translations.CONFIRM_CANCELLATION} className="text-xl" />,
      body: (
        <OrderDeliveryConfirmation
          order={order}
          assignDriverToOrder={assignDriverToOrder}
          updatedCourierId={updatedCourierId}
          acceptOrder={acceptOrder}
          sendChangeStatus={sendChangeStatus}
          onCancel={close}
          isUnAssignCourier={isUnAssignCourier}
          isAssigneeSupportCancellation={isAssigneeSupportCancellation}
          assignee={assignee}
        />
      ),
      size: 'max-w-xl',
    });

  const { handleAssignCourierToOrder } = useHandleAssignUnAssignCourierToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const { handleAssignDriverToOrder } = useHandleAssignUnAssignDriverToOrder({
    order,
    mutateFetchVerdDrivers,
  });

  const restaurantCouriersWithLogos = useGetCourierInfoWithLogo(order);

  const assignedCourier = restaurantCouriersWithLogos?.find(
    courier => Number(courier.courierId) === orderDeliveryCourierId,
  );
  const hasCourierInfo = orderDeliveryCourierId && isOrderDeliveryCourierNotVerd;
  const hasRiders = riders?.length !== 0;
  const isCourierNotDeclinedTheRequest = !!assignedCourier && deliveryOrderStatus !== ORDER_RIDER_STATUS.DECLINED;

  const orderDeliveryPhoneNumber = order?.deliveryCourier?.driverPhoneNumber;
  const assignedDriver = riders?.find(driver => driver.phone === orderDeliveryPhoneNumber);
  const isOrderAssignedToCourierOrRider = !!assignedDriver?.id || !!assignedCourier?.courierId;
  const quickAssignCourierId = order?.deliveryCourierId;

  const AddRiderModalBody = () => (
    <AddRiderModal
      close={close}
      closeable={closeable}
      mutateFetchVerdDrivers={mutateFetchVerdDrivers}
      sendChangeStatus={sendChangeStatus}
      handleAssignDriverToOrder={handleAssignDriverToOrder}
      setShouldShowRiderModal={setShouldShowRiderModal}
    />
  );

  console.log({
    cond: !(hasRiders || hasCourierInfo) && shouldShowRiderModal,
    shouldShowRiderModal,
    hasRiders,
    hasCourierInfo,
    assignedCourier,
  });

  const ShowNextStatus = ({ open: openAsssignModal, close: closeAssignModal, assignModalCloseable }) => {
    switch (orderStatus) {
      case ORDER_STATUS.SUBMITTED:
        return (
          <div className={cx('flex flex-row', lang === 'ar' && 'flex-row-reverse')}>
            <Button
              rounded
              isLoading={isUpdatingStatus}
              isDisabled={shouldDisableActionBtns}
              onClick={() => {
                if (!(hasRiders || hasCourierInfo) && shouldShowRiderModal)
                  open({
                    body: AddRiderModalBody(),
                  });
                else if (
                  !(assignedDriver || assignedCourier) &&
                  (restaurantCouriersWithLogos?.length > 0 || riders?.length > 0)
                )
                  openAsssignModal({
                    body: (
                      <AssignDeliveryModal
                        close={closeAssignModal}
                        handleAssignDriverToOrder={handleAssignDriverToOrder}
                        handleAssignCourierToOrder={handleAssignCourierToOrder}
                        openOrderDeliveryConfirmationPopUp={openOrderDeliveryConfirmationPopUp}
                        isCourierNotDeclinedTheRequest={isCourierNotDeclinedTheRequest}
                        assigned={isOrderAssignedToCourierOrRider}
                        restaurantCouriersWithLogos={restaurantCouriersWithLogos}
                        riders={riders}
                        quickAssignCourierId={quickAssignCourierId}
                        sendChangeStatus={sendChangeStatus}
                        closeable={assignModalCloseable}
                      />
                    ),
                  });
                else sendChangeStatus({ status: ORDER_STATUS.ACCEPTED, sendCourierId: false });
              }}
              text={translate(translations.ACCEPT_ORDER)}
              data-testid="accept-order-button"
            />
          </div>
        );
      case ORDER_STATUS.ACCEPTED:
        return (
          <Button
            rounded
            isLoading={isUpdatingStatus}
            isDisabled={shouldDisableActionBtns}
            onClick={() => {
              sendChangeStatus({ status: ORDER_STATUS.READY, sendCourierId: false });
            }}
            size="medium"
            text={translate(translations.READY_ORDER)}
            data-testid="ready-order-button"
          />
        );
      case ORDER_STATUS.READY:
        if (deliveryType === DELIVERY_BUSY_MODE.PICKUP || deliveryType === DELIVERY_BUSY_MODE.BEACH) {
          return (
            <Button
              rounded
              isLoading={isUpdatingStatus}
              isDisabled={shouldDisableActionBtns}
              onClick={() => {
                sendChangeStatus({ status: ORDER_STATUS.FULFILLED, sendCourierId: false });
              }}
              size="medium"
              text={translate(translations.FULFILL_ORDER)}
              data-testid="fulfill-order-button"
            />
          );
        }
        return (
          <Button
            rounded
            isLoading={isUpdatingStatus}
            isDisabled={shouldDisableActionBtns}
            onClick={() => {
              sendChangeStatus({ status: ORDER_STATUS.DISPATCHED, sendCourierId: false });
            }}
            size="medium"
            text={translate(translations.DISPATCH_ORDER)}
            data-testid="dispatch-order-button"
          />
        );

      case ORDER_STATUS.DISPATCHED:
        return (
          <Button
            rounded
            isLoading={isUpdatingStatus}
            isDisabled={shouldDisableActionBtns}
            onClick={() => {
              sendChangeStatus({ status: ORDER_STATUS.DELIVERED, sendCourierId: false });
            }}
            size="medium"
            text={translate(translations.DELIVER_ORDER)}
            data-testid="deliver-order-button"
          />
        );
      case ORDER_STATUS.DELIVERED:
      default:
        return null;
    }
  };

  const AssignScheduledNow = () => (
    <Button
      rounded
      isLoading={isUpdatingStatus}
      isDisabled={shouldDisableActionBtns}
      onClick={() => {
        assignScheduledOrderNow({
          orderNumber: order.number,
          userId: user?.id,
        });
      }}
      size="medium"
      variant="tertiary"
      text={<Text value={translations.DELIVER_NOW} />}
      data-testid="assign-now-button"
    />
  );

  return isPendingScheduledOrder && !isInternalDelivery ? (
    <div className="flex flex-row justify-between">
      <div className={cx(lang === 'ar' ? 'ml-4' : 'mr-4')}>
        <AssignScheduledNow />
      </div>
      <ShowNextStatus />
    </div>
  ) : (
    <div className="flex justify-end">
      <Modal customHeight="h-3/4">
        {({ open: openModal, close: closeModal, closeable: assignModalCloseable }) => (
          <ShowNextStatus open={openModal} close={closeModal} assignModalCloseable={assignModalCloseable} />
        )}
      </Modal>
    </div>
  );
};

DeliveryActionButtons.propTypes = {
  order: PropTypes.shape({ status: PropTypes.string.isRequired }),
  isUpdatingStatus: PropTypes.bool.isRequired,
  shouldDisableActionBtns: PropTypes.bool,
  sendChangeStatus: PropTypes.func.isRequired,
  deliveryType: PropTypes.string.isRequired,
};
export default DeliveryActionButtons;
